import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationList } from '../interfaces/notification/notification-list.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private allNotifications = new BehaviorSubject<NotificationList>(
    {} as NotificationList
  );
  private headerNotifications = new BehaviorSubject<NotificationList>(
    {} as NotificationList
  );

  allNotifications$ = this.allNotifications.asObservable();
  headerNotifications$ = this.headerNotifications.asObservable();

  constructor(private http: HttpClient) {}

  getAllNotifications(per_page?: number | null, page?: number | null) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    let paramsMap = new Map<any, any>();

    if (per_page) {
      paramsMap.set('per_page', per_page);
    }
    if (page) {
      paramsMap.set('page', page);
    }

    let params = new HttpParams();
    paramsMap.forEach((value: any, key: any) => {
      params = params.set(key, value);
    });

    return this.http
      .get<NotificationList>(
        `${environment.API_ZEN}${environment.API_GET_ALL_NOTIFICATIONS}`,
        { headers, params }
      )
      .subscribe({
        next: (res) => {
          this.allNotifications.next(res);
        },
        error: (err) => {
          // console.log(err);
        },
      });
  }

  getHeaderNotifications(per_page?: number | null, page?: number | null) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    let paramsMap = new Map<any, any>();

    if (per_page) {
      paramsMap.set('per_page', per_page);
    }
    if (page) {
      paramsMap.set('page', page);
    }

    let params = new HttpParams();
    paramsMap.forEach((value: any, key: any) => {
      params = params.set(key, value);
    });

    return this.http
      .get<NotificationList>(
        `${environment.API_ZEN}${environment.API_GET_ALL_NOTIFICATIONS}`,
        { headers, params }
      )
      .subscribe({
        next: (res) => {
          this.headerNotifications.next(res);
        },
        error: (err) => {
          // console.log(err);
        },
      });
  }

  notificationsRead(id?: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    let paramsMap = new Map<any, any>();
    paramsMap.set('id', id);

    let params = new HttpParams();

    if (id !== undefined) {
      paramsMap.forEach((value: any, key: any) => {
        params = params.set(key, value);
      });
    }

    return this.http.patch(
      `${environment.API_ZEN}${environment.API_PATCH_NOTIFICAION_READ}`,
      {},
      {
        headers,
        params,
      }
    );
  }
}
