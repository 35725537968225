<div class="flex sm:flex-row flex-col justify-between my-auto py-2">
  <!-- <router-outlet> -->
  <h2
    class="sm:ml-[42px] ml-[10px] sm:leading-0 leading-[43px] sm:text-[32px] text-[20px] font-bold text-white"
  >
    {{ title.getTitle() }}
  </h2>
  <!-- </router-outlet> -->
  <div
    class="flex sm:gap-[36px] sm:ml-0 ml-[10px] lg:mr-[45px] mr-[7px]"
    *ngIf="user"
  >
    <p-overlayPanel #bell [styleClass]="notificationsOverlay">
      <ng-container *ngIf="user.unread_notifications >= 1">
        <ng-container *ngIf="allNotifications$ | async as notifications">
          <a
            [routerLink]="['admin/notifications']"
            [queryParams]="{ p: 1 }"
            (click)="onViewNotification(notification.id); bell.hide()"
            *ngFor="let notification of notifications.data | slice : 0 : 5"
            class="text-xs leading-4 font-bold text-grey-chateau block max-w-[200px] pb-1.5 mb-1.5 border-b-2 border-solid"
            >{{ notification.text }}</a
          >
        </ng-container>
      </ng-container>

      <p
        *ngIf="user.unread_notifications < 1"
        class="text-xs leading-4 font-bold text-grey-chateau block max-w-[115px]"
      >
        Nemate novih obavještenja.
      </p>
    </p-overlayPanel>
    <button
      class="relative sm:order-1 order-2 sm:mr-0 mr-[20px]"
      (click)="bell.toggle($event)"
      label="Show"
    >
      <p-badge
        *ngIf="user.unread_notifications >= 1"
        class="text-sm"
        [value]="user.unread_notifications.toString()"
      ></p-badge>
      <svg
        class="my-auto"
        width="19"
        height="22"
        viewBox="0 0 19 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.8571 17.8095V18.8571H0V17.8095L2.09524 15.7143V9.42857C2.09524 6.18095 4.2219 3.32095 7.33333 2.39905V2.09524C7.33333 1.53955 7.55408 1.00661 7.94701 0.613681C8.33995 0.220748 8.87288 0 9.42857 0C9.98426 0 10.5172 0.220748 10.9101 0.613681C11.3031 1.00661 11.5238 1.53955 11.5238 2.09524V2.39905C14.6352 3.32095 16.7619 6.18095 16.7619 9.42857V15.7143L18.8571 17.8095ZM11.5238 19.9048C11.5238 20.4605 11.3031 20.9934 10.9101 21.3863C10.5172 21.7793 9.98426 22 9.42857 22C8.87288 22 8.33995 21.7793 7.94701 21.3863C7.55408 20.9934 7.33333 20.4605 7.33333 19.9048"
          fill="white"
        />
      </svg>
    </button>
    <div class="flex gap-[17px] sm:order-2 order-1">
      <div>
        <h3 class="sm:text-xl text-base font-bold text-white">
          {{ user.first_name }}
          {{ user.last_name }}
        </h3>
        <h6 class="text-sm font-medium text-white text-right">
          {{ user.role.name }}
        </h6>
      </div>
      <p-overlayPanel #profile [styleClass]="profileOverlay">
        <a
          href="#"
          class="text-xs leading-4 font-bold text-grey-chateau block max-w-[58px] text-center mb-[11px]"
          >ZEN CAR početna</a
        >
        <button
          (click)="onLogut(); profile.hide()"
          type="button"
          class="text-xs leading-4 font-bold text-grey-chateau block max-w-[58px] text-center"
        >
          Odjavi se
        </button>
      </p-overlayPanel>
      <button
        class="my-auto xl:block hidden"
        (click)="profile.toggle($event)"
        label="Show"
      >
        <svg
          width="31"
          height="38"
          viewBox="0 0 18 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.81228 0.361012C9.29515 0.131177 8.70485 0.131176 8.18772 0.361012L1.18772 3.47212C0.465469 3.79313 0 4.50937 0 5.29975V10C0 15.3796 3.60778 20.4209 8.52844 21.8729C8.83619 21.9637 9.16381 21.9637 9.47156 21.8729C14.3922 20.4209 18 15.3796 18 10V5.29975C18 4.50937 17.5345 3.79313 16.8123 3.47212L9.81228 0.361012ZM9 3.9C9.59334 3.9 10.1734 4.07595 10.6667 4.40559C11.1601 4.73524 11.5446 5.20377 11.7716 5.75195C11.9987 6.30013 12.0581 6.90333 11.9424 7.48527C11.8266 8.06721 11.5409 8.60176 11.1213 9.02132C10.7018 9.44088 10.1672 9.7266 9.58527 9.84236C9.00333 9.95811 8.40013 9.8987 7.85195 9.67164C7.30377 9.44458 6.83524 9.06006 6.50559 8.56671C6.17595 8.07336 6 7.49334 6 6.9C6 6.10435 6.31607 5.34129 6.87868 4.77868C7.44129 4.21607 8.20435 3.9 9 3.9ZM9 11.8C10.7938 11.8 14.1964 12.6768 14.879 14.2933C15.032 14.6556 14.8901 15.0596 14.646 15.368C14.0364 16.1384 13.2741 16.7776 12.4047 17.2442C11.3578 17.806 10.1881 18.1 9 18.1C7.81186 18.1 6.64218 17.806 5.59527 17.2442C4.72589 16.7776 3.96362 16.1384 3.35398 15.368C3.10994 15.0596 2.96802 14.6556 3.12101 14.2933C3.80364 12.6768 7.20622 11.8 9 11.8Z"
            fill="white"
          />
        </svg>
      </button>
    </div>
  </div>
</div>
