<main class="grid layout-grid">
  <ng-container *ngIf="activeUser$ | async as activeUser">
    <nav
      *ngIf="activeUser !== null"
      class="fixed z-10 -translate-x-80 xl:translate-x-0 lg:block h-screen w-80 bg-black navigation"
      [ngClass]="{ '-translate-x-80': menuClosed }"
    >
      <app-side-nav (openMenuChangedValue)="closeMenu()"></app-side-nav>
    </nav>
    <header
      *ngIf="activeUser !== null"
      class="flex min-h-[70px] bg-gradient-to-l from-doger-blue to-zaffre header"
    >
      <app-header [user]="activeUser" class="my-auto w-full"></app-header>
      <span
        class="xl:hidden inline-block my-auto mr-8 cursor-pointer"
        (click)="toggleMenu()"
      >
        <svg
          width="43"
          height="31"
          viewBox="0 0 43 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 3H40.5M3 15.5H40.5M3 28H40.5"
            stroke="white"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
        </svg>
      </span>
    </header>
  </ng-container>

  <div class="content">
    <router-outlet></router-outlet>
  </div>
</main>
<p-toast></p-toast>
