import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'admin/users-list',
    title: 'ADMIN NALOZI',
    loadChildren: () =>
      import('./pages/adminUsers/user-list/user-list.module').then(
        (m) => m.UserListModule
      ),
  },
  {
    path: 'admin/location-list',
    title: 'LOKACIJE',
    loadChildren: () =>
      import('./pages/location/location-list/location-list.module').then(
        (m) => m.LocationListModule
      ),
  },
  {
    path: 'admin/category-list',
    title: 'KATEGORIJE',
    loadChildren: () =>
      import('./pages/category/category-list/category-list.module').then(
        (m) => m.CategoryListModule
      ),
  },
  {
    path: 'admin/cars-list',
    title: 'AUTOMOBILI',
    loadChildren: () =>
      import('./pages/cars/cars-list/cars-list.module').then(
        (m) => m.CarsListModule
      ),
  },
  {
    path: 'admin/feature-list',
    title: 'POGODNOSTI',
    loadChildren: () =>
      import('./pages/features/feture-list/feture-list.module').then(
        (m) => m.FetureListModule
      ),
  },
  {
    path: 'admin/client-list',
    title: 'KLIJENTI',
    loadChildren: () =>
      import('./pages/client/client-list/client-list.module').then(
        (m) => m.ClientListModule
      ),
  },
  {
    path: 'admin/reservation-list',
    title: 'REZERVACIJE',
    loadChildren: () =>
      import(
        './pages/reservation/reservation-list/reservation-list.module'
      ).then((m) => m.ReservationListModule),
  },
  {
    path: 'admin/promotions-list',
    loadChildren: () =>
      import('./pages/promotions/promotions-list/promotions-list.module').then(
        (m) => m.PromotionsListModule
      ),
  },
  {
    path: 'admin/user-create',
    title: 'ADMIN NALOZI',
    loadChildren: () =>
      import('./pages/adminUsers/add-edit-user/add-edit-user.module').then(
        (m) => m.AddEditUserModule
      ),
  },

  {
    path: 'admin/location-create',
    title: 'LOKACIJE',
    loadChildren: () =>
      import(
        './pages/location/add-edit-location/add-edit-location.module'
      ).then((m) => m.AddEditLocationModule),
  },
  {
    path: 'admin/category-create',
    title: 'KATEGORIJE',
    loadChildren: () =>
      import(
        './pages/category/add-edit-category/add-edit-category.module'
      ).then((m) => m.AddEditCategoryModule),
  },

  {
    path: 'admin/car-create',
    title: 'AUTOMOBILI',
    loadChildren: () =>
      import('./pages/cars/add-edit-car/add-edit-car.module').then(
        (m) => m.AddEditCarModule
      ),
  },
  {
    path: 'admin/feature-create',
    title: 'POGODNOSTI',
    loadChildren: () =>
      import('./pages/features/add-edit-feature/add-edit-feature.module').then(
        (m) => m.AddEditFeatureModule
      ),
  },
  {
    path: 'admin/client-create',
    title: 'KLIJENTI',
    loadChildren: () =>
      import('./pages/client/add-edit-client/add-edit-client.module').then(
        (m) => m.AddEditClientModule
      ),
  },
  {
    path: 'admin/reservation-create',
    title: 'REZERVACIJE',
    loadChildren: () =>
      import(
        './pages/reservation/add-edit-reservation/add-edit-reservation.module'
      ).then((m) => m.AddEditReservationModule),
  },
  {
    path: 'admin/promotion-create',
    loadChildren: () =>
      import(
        './pages/promotions/promotions-add-edit/promotions-add-edit.module'
      ).then((m) => m.PromotionsAddEditModule),
  },
  {
    path: 'admin/users-list/:id',
    title: 'ADMIN NALOZI',
    loadChildren: () =>
      import('./pages/adminUsers/add-edit-user/add-edit-user.module').then(
        (m) => m.AddEditUserModule
      ),
  },
  {
    path: 'admin/location-list/:id',
    title: 'LOKACIJE',
    loadChildren: () =>
      import(
        './pages/location/add-edit-location/add-edit-location.module'
      ).then((m) => m.AddEditLocationModule),
  },
  {
    path: 'admin/category-list/:id',
    title: 'KATEGORIJE',
    loadChildren: () =>
      import(
        './pages/category/add-edit-category/add-edit-category.module'
      ).then((m) => m.AddEditCategoryModule),
  },
  {
    path: 'admin/cars-list/:id',
    title: 'AUTOMOBILI',
    loadChildren: () =>
      import('./pages/cars/add-edit-car/add-edit-car.module').then(
        (m) => m.AddEditCarModule
      ),
  },

  {
    path: 'admin/feature-list/:id',
    title: 'POGODNOSTI',
    loadChildren: () =>
      import('./pages/features/add-edit-feature/add-edit-feature.module').then(
        (m) => m.AddEditFeatureModule
      ),
  },

  {
    path: 'admin/client-list/:id',
    title: 'KLIJENTI',
    loadChildren: () =>
      import('./pages/client/add-edit-client/add-edit-client.module').then(
        (m) => m.AddEditClientModule
      ),
  },
  {
    path: 'admin/reservation-list/:id',
    title: 'REZERVACIJE',
    loadChildren: () =>
      import(
        './pages/reservation/add-edit-reservation/add-edit-reservation.module'
      ).then((m) => m.AddEditReservationModule),
  },
  {
    path: 'admin/promotions-list/:id',
    loadChildren: () =>
      import(
        './pages/promotions/promotions-add-edit/promotions-add-edit.module'
      ).then((m) => m.PromotionsAddEditModule),
  },
  {
    path: 'admin/notifications',
    loadChildren: () =>
      import(
        './pages/notifications/notification-list/notification-list.module'
      ).then((m) => m.NotificationListModule),
  },

  {
    path: '**',
    title: 'ERROR 404',
    loadChildren: () =>
      import('./pages/page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
