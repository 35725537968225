import { EventEmitter, Injectable, Output } from '@angular/core';
import { MenuItem, MenuItemCommandEvent } from 'primeng/api';
import { Subject, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssociateAdminService {
  associateItems: MenuItem[] | undefined;
  @Output() openMenuChangedValue = new EventEmitter();

  private menuCloseEv = new Subject<void>();
  menuCloseEv$ = this.menuCloseEv.asObservable();
  private superAdminMenu: MenuItem[] = [
    {
      label: 'REZERVACIJE',
      icon: 'reservation-icon',
      expanded: true,
      items: [
        {
          label: 'Sve rezervacije',
          command: () => this.closeMenu(),
          routerLink: 'admin/reservation-list',
          routerLinkActiveOptions: '{ exact: true }',
        },
        {
          label: 'Kreiraj rezervaciju',
          command: () => this.closeMenu(),
          routerLink: 'admin/reservation-create',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
    // {
    //   label: 'KLIJENTI',
    //   icon: 'client-icon',
    //   items: [
    //     {
    //       label: 'Svi klijenti',
    //       command: () => this.closeMenu(),
    //       routerLink: 'admin/client-list',
    //       routerLinkActiveOptions: '{ exact: true }',
    //     },
    //     {
    //       label: 'Kreiraj klijenta',
    //       command: () => this.closeMenu(),
    //       routerLink: 'admin/client-create',
    //       routerLinkActiveOptions: '{ exact: true }',
    //     },
    //   ],
    // },
    {
      label: 'POGODNOSTI',
      icon: 'benefits-icon',
      items: [
        {
          label: 'Sve pogodnosti',
          command: () => this.closeMenu(),
          routerLink: 'admin/feature-list',
          routerLinkActiveOptions: '{ exact: true }',
        },
        {
          label: 'Dodaj pogodnost',
          command: () => this.closeMenu(),
          routerLink: 'admin/feature-create',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
    {
      label: 'KATEGORIJE',
      icon: 'category-icon',
      items: [
        {
          label: 'Sve kategorije',
          command: () => this.closeMenu(),
          routerLink: 'admin/category-list',
          routerLinkActiveOptions: '{ exact: true, }',
        },
        {
          label: 'Dodaj kategoriju',
          command: () => this.closeMenu(),
          routerLink: 'admin/category-create',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
    {
      label: 'PROMOCIJE',
      icon: 'promotions-icon',
      items: [
        {
          label: 'Sve promocije',
          command: () => this.closeMenu(),
          routerLink: 'admin/promotions-list',
          routerLinkActiveOptions: '{ exact: true, }',
        },
        {
          label: 'Dodaj promociju',
          command: () => this.closeMenu(),
          routerLink: 'admin/promotion-create',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
    {
      label: 'AUTOMOBILI',
      icon: 'cars-icon',
      items: [
        {
          label: 'Svi automobili',
          command: () => this.closeMenu(),
          routerLink: 'admin/cars-list',
          routerLinkActiveOptions: '{ exact: true }',
        },
        {
          label: 'Dodaj automobil',
          command: () => this.closeMenu(),
          routerLink: 'admin/car-create',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
    {
      label: 'OBAVJEŠTENJA',
      icon: 'notification-icon',
      expanded: false,
      items: [
        {
          label: 'Sva obavještenja',
          command: () => this.closeMenu(),
          routerLink: 'admin/notifications',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
    {
      label: 'LOKACIJE',
      icon: 'location-icon',
      items: [
        {
          label: 'Sve lokacije',
          command: () => this.closeMenu(),
          routerLink: 'admin/location-list',
          routerLinkActiveOptions: '{ exact: true }',
        },
        {
          label: 'Dodaj lokaciju',
          command: () => this.closeMenu(),
          routerLink: 'admin/location-create',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
    {
      label: 'ADMIN NALOZI',
      icon: 'admin-icon',
      items: [
        {
          label: 'Svi nalozi',
          command: () => this.closeMenu(),
          routerLink: 'admin/users-list',
          routerLinkActiveOptions: '{ exact: true }',
        },
        {
          label: 'Dodaj nalog',
          command: () => this.closeMenu(),
          routerLink: 'admin/user-create',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
  ];

  private officeAdminMenu: MenuItem[] = [
    {
      label: 'REZERVACIJE',
      icon: 'reservation-icon',
      expanded: true,
      items: [
        {
          label: 'Sve rezervacije',
          command: () => this.closeMenu(),
          routerLink: 'admin/reservation-list',
          routerLinkActiveOptions: '{ exact: true }',
        },
        {
          label: 'Kreiraj rezervaciju',
          command: () => this.closeMenu(),
          routerLink: 'admin/reservation-create',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
    // {
    //   label: 'KLIJENTI',
    //   icon: 'client-icon',
    //   items: [
    //     {
    //       label: 'Svi klijenti',
    //       command: () => this.closeMenu(),
    //       routerLink: 'admin/client-list',
    //       routerLinkActiveOptions: '{ exact: true }',
    //     },
    //     {
    //       label: 'Kreiraj klijenta',
    //       command: () => this.closeMenu(),
    //       routerLink: 'admin/client-create',
    //       routerLinkActiveOptions: '{ exact: true }',
    //     },
    //   ],
    // },
    {
      label: 'POGODNOSTI',
      icon: 'benefits-icon',
      items: [
        {
          label: 'Sve pogodnosti',
          command: () => this.closeMenu(),
          routerLink: 'admin/feature-list',
          routerLinkActiveOptions: '{ exact: true }',
        },
        {
          label: 'Dodaj pogodnost',
          command: () => this.closeMenu(),
          routerLink: 'admin/feature-create',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
    {
      label: 'KATEGORIJE',
      icon: 'category-icon',
      items: [
        {
          label: 'Sve kategorije',
          command: () => this.closeMenu(),
          routerLink: 'admin/category-list',
          routerLinkActiveOptions: '{ exact: true, }',
        },
        {
          label: 'Dodaj kategoriju',
          command: () => this.closeMenu(),
          routerLink: 'admin/category-create',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
    {
      label: 'PROMOCIJE',
      icon: 'promotions-icon',
      items: [
        {
          label: 'Sve promocije',
          command: () => this.closeMenu(),
          routerLink: 'admin/promotions-list',
          routerLinkActiveOptions: '{ exact: true, }',
        },
        {
          label: 'Dodaj promociju',
          command: () => this.closeMenu(),
          routerLink: 'admin/promotion-create',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
    {
      label: 'AUTOMOBILI',
      icon: 'cars-icon',
      items: [
        {
          label: 'Svi automobili',
          command: () => this.closeMenu(),
          routerLink: 'admin/cars-list',
          routerLinkActiveOptions: '{ exact: true }',
        },
        {
          label: 'Dodaj automobil',
          command: () => this.closeMenu(),
          routerLink: 'admin/car-create',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
    {
      label: 'OBAVJEŠTENJA',
      icon: 'notification-icon',
      expanded: false,
      items: [
        {
          label: 'Sva obavještenja',
          command: () => this.closeMenu(),
          routerLink: 'admin/notifications',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
    {
      label: 'LOKACIJE',
      icon: 'location-icon',
      items: [
        {
          label: 'Sve lokacije',
          command: () => this.closeMenu(),
          routerLink: 'admin/location-list',
          routerLinkActiveOptions: '{ exact: true }',
        },
        {
          label: 'Dodaj lokaciju',
          command: () => this.closeMenu(),
          routerLink: 'admin/location-create',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
  ];

  private operativeAdminMenu: MenuItem[] = [
    {
      label: 'REZERVACIJE',
      icon: 'reservation-icon',
      expanded: true,
      items: [
        {
          label: 'Sve rezervacije',
          command: () => this.closeMenu(),
          routerLink: 'admin/reservation-list',
          routerLinkActiveOptions: '{ exact: true }',
        },
        {
          label: 'Kreiraj rezervaciju',
          command: () => this.closeMenu(),
          routerLink: 'admin/reservation-create',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
    {
      label: 'AUTOMOBILI',
      icon: 'cars-icon',
      expanded: false,
      items: [
        {
          label: 'Svi automobili',
          command: () => this.closeMenu(),
          routerLink: 'admin/cars-list',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
    {
      label: 'OBAVJEŠTENJA',
      icon: 'notification-icon',
      expanded: false,
      items: [
        {
          label: 'Sva obavještenja',
          command: () => this.closeMenu(),
          routerLink: 'admin/notifications',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
  ];

  private associateAdminMenu: MenuItem[] = [
    {
      label: 'REZERVACIJE',
      icon: 'reservation-icon',
      expanded: true,
      items: [
        {
          label: 'Sve rezervacije',
          command: () => this.closeMenu(),
          routerLink: 'admin/reservation-list',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
    {
      label: 'AUTOMOBILI',
      icon: 'cars-icon',
      expanded: true,
      items: [
        {
          label: 'Svi automobili',
          command: () => this.closeMenu(),
          routerLink: 'admin/cars-list',
          routerLinkActiveOptions: '{ exact: true }',
        },
      ],
    },
  ];

  getNavByRole(id: 1 | 2 | 3 | 4) {
    if (id === 1) {
      return this.superAdminMenu;
    }
    if (id === 2) {
      return this.officeAdminMenu;
    }
    if (id === 3) {
      return this.operativeAdminMenu;
    }
    if (id === 4) {
      return this.associateAdminMenu;
    }
    return;
  }

  closeMenu() {
    this.menuCloseEv.next();
  }
}
