<div class="overflow-auto h-full flex flex-col">
  <div class="m-[30px] flex justify-center pb-[32px] border-b-2">
    <svg
      width="146"
      height="80"
      viewBox="0 0 146 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M74.8157 0C61.0364 0 49.4149 1.46116 45.1424 3.40937C40.8699 5.35758 36.1103 14.7965 36.1103 14.7965C36.1103 14.7965 33.188 14.254 32.1338 13.2799C31.0795 12.3058 33.7737 9.67324 27.2941 9.67324C20.8144 9.67324 20.5123 13.1319 27.2941 15.2219C34.0758 17.312 34.0758 18.3046 34.0758 18.3046C26.5912 18.3292 23.7429 22.1948 22.3187 28.6683C20.8946 35.1418 19.569 44.5993 24.4087 49.0752C22.9229 42.725 25.8884 39.0444 33.1449 42.2935C24.8403 35.8323 23.4285 27.127 27.9969 25.9433C32.5653 24.7596 48.5641 34.5253 48.5641 34.5253C43.447 29.0752 34.4334 23.1381 34.4334 21.6954C34.4334 20.2528 40.8699 7.21948 46.4926 4.93218C52.1153 2.64488 74.8157 2.18866 74.8157 2.18866V0Z"
        fill="white"
      />
      <path
        d="M74.8164 0C88.6265 0 100.211 1.46116 104.483 3.40937C108.756 5.35758 113.516 14.7965 113.516 14.7965C113.516 14.7965 116.444 14.254 117.492 13.2799C118.54 12.3058 115.858 9.67324 122.332 9.67324C128.805 9.67324 129.114 13.1319 122.332 15.2219C115.55 17.312 115.55 18.3046 115.55 18.3046C123.041 18.3046 125.889 22.1455 127.313 28.6437C128.737 35.1418 130.088 44.5993 125.217 49.0752C126.703 42.725 123.737 39.0444 116.481 42.2935C124.792 35.8385 126.191 27.1023 121.629 25.9494C117.067 24.7965 101.062 34.5253 101.062 34.5253C106.179 29.0752 115.192 23.1381 115.192 21.6954C115.192 20.2528 108.756 7.21948 103.139 4.93218C97.5229 2.64488 74.8164 2.18866 74.8164 2.18866V0Z"
        fill="white"
      />
      <path
        d="M110.537 11.9976C113.582 17.0592 74.8155 16.8866 74.8155 16.8866C74.8155 16.8866 36.0423 17.0592 39.088 11.9976L37.2754 15.4501C38.9153 16.9606 61.0054 17.6079 74.8155 17.6079C88.6256 17.6079 110.709 16.9914 112.349 15.4501L110.537 11.9976Z"
        fill="white"
      />
      <path
        d="M97.1087 35.0183C91.4305 36.5904 74.8153 36.3747 74.8153 36.3747C74.8153 36.3747 58.1691 36.5658 52.5156 34.9937C56.4367 36.8864 64.396 38.0763 74.8153 38.0763C85.2345 38.0763 93.1876 36.911 97.1087 35.0183Z"
        fill="white"
      />
      <path
        d="M98.1441 41.1343C100.949 45.752 98.7606 48.9271 91.276 49.4327C84.186 49.9136 76.5165 49.9382 75.715 49.9382C74.7409 49.9382 65.4376 49.9382 58.3475 49.4327C50.8568 48.9271 48.6743 45.7335 51.4795 41.1343C45.3944 44.4512 39.6237 44.3094 36.0664 42.6509C36.9789 44.5745 42.1515 48.2428 47.0282 50.0122C51.7754 51.7323 62.6879 51.8618 75.678 51.8618H75.8321C88.7791 51.8618 97.9098 51.7076 102.651 50.0122C107.528 48.2428 112.7 44.5745 113.619 42.6509C110 44.3094 104.229 44.4512 98.1441 41.1343Z"
        fill="white"
      />
      <path
        d="M5.66599 75.2158H19.5747V79.9815H1.1962C1.04207 79.9815 0.869446 79.8458 0.684489 79.5684C0.462796 79.2223 0.302219 78.8407 0.209766 78.4402C0.0722904 77.9064 0.00187514 77.3576 0.000148303 76.8064C-0.00462751 76.151 0.105979 75.4999 0.326905 74.8828C0.584361 74.1547 0.952424 73.4706 1.41815 72.8545C1.99916 72.1014 2.70629 71.4548 3.50816 70.9433L15.7585 63.9026H0.696819V59.0752H18.3355C18.5177 59.0887 18.6949 59.1407 18.8554 59.2279C19.0159 59.315 19.156 59.4353 19.2665 59.5807C19.601 59.9668 19.8472 60.4213 19.9878 60.9124C20.1729 61.512 20.2725 62.1346 20.2838 62.762C20.3017 63.4903 20.1933 64.2162 19.9632 64.9075C19.6951 65.6855 19.2946 66.4113 18.7794 67.053C18.1542 67.8074 17.3903 68.4352 16.5291 68.9026L5.66599 75.2158Z"
        fill="white"
      />
      <path
        d="M21.9727 66.2084C21.9735 65.3125 22.1386 64.4244 22.4597 63.5881C22.7818 62.7415 23.2702 61.9679 23.8962 61.3132C24.5603 60.6252 25.3546 60.0761 26.2328 59.6979C27.234 59.2717 28.3138 59.0616 29.4018 59.0814H42.0096V63.9087H29.7532C29.3275 63.9041 28.9062 63.9946 28.5201 64.1738C28.169 64.3425 27.8579 64.5843 27.6077 64.8828C27.3528 65.1897 27.1563 65.5408 27.0281 65.9186C26.8906 66.3152 26.8198 66.7319 26.8185 67.1516H42.0096V71.979H26.7939C26.7819 72.771 27.0629 73.5395 27.583 74.1368C27.8518 74.444 28.1867 74.6863 28.5626 74.8454C28.9385 75.0045 29.3455 75.0763 29.7532 75.0555H42.0096V79.9383H29.4018C28.295 79.9689 27.1948 79.7586 26.1773 79.3218C25.3012 78.9353 24.511 78.3777 23.8531 77.6818C22.6816 76.4053 22.0229 74.7411 22.0035 73.0086L21.9727 66.2084Z"
        fill="white"
      />
      <path
        d="M61.0351 79.9689C60.5574 79.97 60.0863 79.858 59.6602 79.6422C59.2041 79.4175 58.7758 79.1402 58.384 78.816C57.9729 78.4723 57.5933 78.0927 57.2496 77.6816C56.9115 77.2877 56.5946 76.8761 56.3002 76.4486L49.4075 66.091V79.9689H44.5801V59.0503H48.2237C48.6077 59.0542 48.9878 59.1273 49.3458 59.2661C49.7555 59.4327 50.1479 59.6392 50.5172 59.8826C50.9101 60.1431 51.2757 60.4426 51.6084 60.7766C51.9519 61.1152 52.2635 61.4846 52.5394 61.8801L60.5049 74.0441V59.0503H65.3877V79.9689H61.0351Z"
        fill="white"
      />
      <path
        d="M78.1699 66.2084C78.1738 65.3128 78.3388 64.4253 78.657 63.5881C78.9791 62.7415 79.4675 61.9679 80.0935 61.3132C80.7587 60.6266 81.5527 60.0777 82.4301 59.6979C83.4312 59.2717 84.5111 59.0616 85.599 59.0814H98.2069V63.9087H85.9504C85.5248 63.9041 85.1035 63.9946 84.7174 64.1738C84.3663 64.3425 84.0552 64.5843 83.8049 64.8828C83.55 65.1897 83.3536 65.5408 83.2254 65.9186C83.0879 66.3152 83.017 66.7319 83.0158 67.1516V72.0037C83.0038 72.7956 83.2848 73.5641 83.8049 74.1615C84.0737 74.4687 84.4086 74.7109 84.7845 74.87C85.1604 75.0291 85.5675 75.101 85.9751 75.0801H98.2316V79.963H85.599C84.4922 79.9936 83.392 79.7832 82.3746 79.3465C81.4979 78.9581 80.7076 78.3984 80.0503 77.7003C78.8789 76.4238 78.2202 74.7596 78.2007 73.0271V66.2453L78.1699 66.2084Z"
        fill="white"
      />
      <path
        d="M119.23 59.0752H121.819V79.9692H116.998V76.4488H105.752C105.342 77.601 105.045 78.7903 104.865 80H99.877C100.028 78.2949 100.376 76.613 100.913 74.9876C102.028 71.5975 103.821 68.4699 106.184 65.7953C107.33 64.4903 108.621 63.3197 110.031 62.3058C111.389 61.3303 112.871 60.5423 114.439 59.963C115.971 59.3869 117.593 59.0863 119.23 59.0752ZM116.998 63.9334C116.049 64.131 115.136 64.4741 114.291 64.9507C113.421 65.4427 112.606 66.0277 111.862 66.6954C111.09 67.391 110.383 68.1544 109.747 68.9766C109.098 69.8097 108.505 70.685 107.972 71.5968H116.998V63.9334Z"
        fill="white"
      />
      <path
        d="M140 79.9694L136.338 72.1889H129.186V79.9694H124.365V67.2752H138.477C138.802 67.2843 139.125 67.2277 139.427 67.1087C139.636 67.0359 139.824 66.9156 139.978 66.7572C140.133 66.5988 140.248 66.4068 140.315 66.1963C140.372 66.0191 140.402 65.8339 140.401 65.6476C140.4 65.4512 140.371 65.256 140.315 65.068C140.254 64.8608 140.149 64.6693 140.006 64.507C139.831 64.3242 139.622 64.1776 139.39 64.0754C139.094 63.9499 138.774 63.891 138.453 63.9028H124.365V59.0754H138.459C139.426 59.0679 140.386 59.2501 141.283 59.6118C142.117 59.9356 142.869 60.441 143.484 61.0914C144.039 61.73 144.479 62.4601 144.784 63.2493C145.08 64.0143 145.23 64.8275 145.228 65.6476C145.222 66.4328 145.072 67.2102 144.784 67.941C144.476 68.7435 144.036 69.4893 143.484 70.1482C143.216 70.4567 142.914 70.7339 142.583 70.9743C142.258 71.2182 141.907 71.425 141.535 71.5908L145.5 79.9694H140Z"
        fill="white"
      />
    </svg>
  </div>
  <div class="menu-section">
    <p-panelMenu [model]="menuItems">
      <ng-template pTemplate="submenuicon">
        <svg
          class="arrow-right order-2 ml-auto"
          width="9"
          height="14"
          viewBox="0 0 9 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.23271 12.7379L7.74447 8.22617C7.90596 8.06502 8.03408 7.87359 8.12149 7.66286C8.20891 7.45212 8.25391 7.22622 8.25391 6.99807C8.25391 6.76993 8.20891 6.54402 8.12149 6.33328C8.03408 6.12255 7.90596 5.93113 7.74447 5.76997L3.23271 1.25821C2.13526 0.160753 0.253906 0.94465 0.253906 2.49502L0.253906 11.5185C0.253906 13.0689 2.13526 13.8354 3.23271 12.7379Z"
            fill="white"
          />
        </svg>
      </ng-template>
    </p-panelMenu>
  </div>
  <button class="xl:hidden flex mx-[30px] mb-[26px]" (click)="onLogut()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="ionicon mr-1"
      viewBox="0 0 512 512"
      height="28px"
      width="30px"
    >
      <path
        d="M304 336v40a40 40 0 01-40 40H104a40 40 0 01-40-40V136a40 40 0 0140-40h152c22.09 0 48 17.91 48 40v40M368 336l80-80-80-80M176 256h256"
        fill="none"
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="32"
      />
    </svg>
    <span class="text-lg font-bold text-white">ODJAVI SE</span>
  </button>
  <span class="block mt-auto text-sm text-center mb-5 text-white font-semibold"
    >Copyrights <span class="text-base">©</span> ZEN CAR 2023</span
  >
</div>
