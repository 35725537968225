import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { PanelMenuModule } from 'primeng/panelmenu';
import { Observable, Subscription, tap } from 'rxjs';
import { User } from 'src/app/interfaces/user/user.model';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { AssociateAdminService } from 'src/app/services/nav.service';

@Component({
  selector: 'app-side-nav',
  standalone: true,
  imports: [CommonModule, PanelMenuModule],
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnDestroy {
  menuItems: MenuItem[] | undefined;
  closeEvSubscription: Subscription = new Subscription();
  roleSubscription: Subscription = new Subscription();
  @Output() openMenuChangedValue = new EventEmitter();

  activeUser$: Observable<null | User> = this.authorizationService.activeUser$;

  constructor(
    private associateAdminService: AssociateAdminService,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit(): void {
    this.roleSubscription = this.activeUser$.subscribe({
      next: (res) => {
        if (res !== null) {
          this.menuItems = this.associateAdminService.getNavByRole(res.role.id);
        }
      },
    });

    this.closeEvSubscription =
      this.associateAdminService.menuCloseEv$.subscribe(() => {
        this.openMenuChangedValue.emit();
      });
  }

  onLogut() {
    this.authorizationService.onLogOut();
  }

  ngOnDestroy(): void {
    this.closeEvSubscription.unsubscribe();
    this.roleSubscription.unsubscribe();
  }
}
