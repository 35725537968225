import { Injectable } from '@angular/core';
import { BehaviorSubject, delay, take, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HighlightService {
  private highlightElement = new BehaviorSubject<string | number | null>(null);

  highlightElement$ = this.highlightElement.asObservable();

  constructor() {}

  setElement(id: string | number) {
    this.highlightElement.next(id);
    timer(3000)
      .pipe(take(1))
      .subscribe(() => {
        this.clearElement();
      });
  }
  clearElement() {
    this.highlightElement.next(null);
  }
}
