import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ServerErrorService {
  constructor(private messageService: MessageService) {}

  handleError(e: HttpErrorResponse) {
    this.messageService.add({
      severity: 'error',
      summary: 'Server error:',
      detail: e.error.message,
      life: environment.APP_NOTIFICATION_LIFE,
    });

    for (const key in e.error.errors) {
      if (e.error.errors.hasOwnProperty(key)) {
        const value = e.error.errors[key];
        for (const message of value) {
          this.messageService.add({
            severity: 'error',
            summary: key,
            detail: message,
            life: environment.APP_NOTIFICATION_LIFE,
          });
        }
      }
    }
  }
}
