import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, of, throwError } from 'rxjs';
import { User } from '../interfaces/user/user.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Login } from '../interfaces/user/login.model';
import { ActiveUser } from '../interfaces/user/active-user.model';
import { Router } from '@angular/router';
import { AllRoles } from '../interfaces/user/roles/all-roles.model';
import { ServerErrorService } from './server-error.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private activeUser = new BehaviorSubject<null | User>(null);
  private allRoles = new BehaviorSubject<AllRoles>({} as AllRoles);

  activeUser$ = this.activeUser.asObservable();
  allRoles$ = this.allRoles.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private serverErrorService: ServerErrorService,
    private messageService: MessageService
  ) {}

  onLogin(email: string, password: string) {
    const params = new HttpParams()
      .set('email', email)
      .set('password', password);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    return this.http
      .post<Login>(
        `${environment.API_ZEN}${environment.API_POST_LOGIN}`,
        {},
        {
          params,
          headers,
        }
      )
      .pipe(
        catchError((e) => {
          this.serverErrorService.handleError(e);
          return throwError(() => e);
        })
      );
  }

  onLogOut() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    return this.http
      .post(
        `${environment.API_ZEN}${environment.API_POST_LOGOUT}`,
        {},
        { headers }
      )
      .pipe(
        catchError((e) => {
          this.serverErrorService.handleError(e);
          return throwError(() => e);
        })
      )
      .subscribe({
        next: (res) => {
          // console.log(res);
          this.removeActiveUser(null);
        },
      });
  }

  setActiveUser(user: User, token: string) {
    localStorage.setItem(environment.APP_LOCAL_STORAGE_TOKEN, token);
    localStorage.setItem(environment.APP_LOCAL_STORAGE_ROLE, user.role.name);
    this.activeUser.next(user);
  }

  removeActiveUser(user: null) {
    localStorage.removeItem(environment.APP_LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(environment.APP_LOCAL_STORAGE_ROLE);
    this.activeUser.next(user);
    this.router.navigate(['/']);
  }

  getActiveUser() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    this.http
      .get<ActiveUser>(`${environment.API_ZEN}${environment.API_GET_PROFILE}`, {
        headers,
      })
      .pipe(
        catchError((e) => {
          this.serverErrorService.handleError(e);
          return throwError(() => e);
        })
      )
      .subscribe({
        next: (res: ActiveUser) => {
          this.activeUser.next(res.data);
          // if (res.data.role.id === 1) {
          //   this.router.navigate([
          //     environment.APP_DEFAULT_PAGE_ROLE_SUPER_ADMIN,
          //   ]);
          // }
          // if (res.data.role.id === 2) {
          //   this.router.navigate([
          //     environment.APP_DEFAULT_PAGE_ROLE_OFFICE_ADMIN,
          //   ]);
          // }
          // if (res.data.role.id === 3) {
          //   this.router.navigate([
          //     environment.APP_DEFAULT_PAGE_ROLE_OPERATIVE_ADMIN,
          //   ]);
          // }
          // if (res.data.role.id === 4) {
          //   this.router.navigate([
          //     environment.APP_DEFAULT_PAGE_ROLE_ASSOCIATE_ADMIN,
          //   ]);
          // }
        },
        error: (err) => {
          this.removeActiveUser(null);
        },
      });
  }

  checkIfUserLogedIn() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    // console.log('user check');
    this.http
      .get<ActiveUser>(`${environment.API_ZEN}${environment.API_GET_PROFILE}`, {
        headers,
      })

      .subscribe({
        next: (res: ActiveUser) => {
          this.activeUser.next(res.data);
          if (res.data.role.id === 1) {
            this.router.navigate([
              environment.APP_DEFAULT_PAGE_ROLE_SUPER_ADMIN,
            ]);
          }
          if (res.data.role.id === 2) {
            this.router.navigate([
              environment.APP_DEFAULT_PAGE_ROLE_OFFICE_ADMIN,
            ]);
          }
          if (res.data.role.id === 3) {
            this.router.navigate([
              environment.APP_DEFAULT_PAGE_ROLE_OPERATIVE_ADMIN,
            ]);
          }
          if (res.data.role.id === 4) {
            this.router.navigate([
              environment.APP_DEFAULT_PAGE_ROLE_ASSOCIATE_ADMIN,
            ]);
          }
        },
        error: (err) => {
          this.removeActiveUser(null);
        },
      });
  }

  getRoles() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    this.http
      .get<AllRoles>(`${environment.API_ZEN}${environment.API_GET_ALL_ROLES}`, {
        headers,
      })
      .pipe(
        catchError((e) => {
          this.serverErrorService.handleError(e);
          return throwError(() => e);
        })
      )
      .subscribe({
        next: (res) => {
          this.allRoles.next(res);
        },
      });
  }
}
