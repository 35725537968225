export const environment = {
  production: false,
  API_ZEN: 'https://staging-api.rentacarzen.com/api/v1',
  API_POST_LOGIN: '/login',
  API_POST_LOGOUT: '/logout',

  API_GET_PROFILE: '/profile',
  API_PATCH_PROFILE: '/profile',

  API_GET_USER_BY_ID: '/backend/users',
  API_GET_ALL_USERS: '/backend/users',
  API_POST_CREATE_USER: '/backend/users',
  API_PATCH_UPDATE_USER: '/backend/users',
  API_DELETE_REMOVE_USER: '/backend/users',
  API_PATCH_RESTORE_USER: '/restore',

  API_GET_LOCATION_BY_ID: '/backend/locations',
  API_GET_ALL_LOCATIONS: '/backend/locations',
  API_POST_CREATE_LOCATION: '/backend/locations',
  API_PATCH_UPDATE_LOCATION: '/backend/locations',
  API_DELETE_REMOVE_LOCATION: '/backend/locations',

  API_GET_CATEGORY_BY_ID: '/backend/categories',
  API_GET_ALL_CATEGORYS: '/backend/categories',
  API_POST_CREATE_CATEGORY: '/backend/categories',
  API_PATCH_UPDATE_CATEGORY: '/backend/categories',
  API_DELETE_REMOVE_CATEGORY: '/backend/categories',

  API_GET_FEATURE_BY_ID: '/backend/features',
  API_GET_ALL_FEATURES: '/backend/features',
  API_POST_CREATE_FEATURE: '/backend/features',
  API_PATCH_UPDATE_FEATURE: '/backend/features',
  API_DELETE_REMOVE_FEATURE: '/backend/features',
  API_PUT_RESTORE_FEATURE: '/restore',

  API_GET_CAR_BY_ID: '/backend/cars',
  API_GET_ALL_CARS: '/backend/cars',
  API_POST_CREATE_CAR: '/backend/cars',
  API_POST_UPDATE_CAR: '/backend/cars',
  API_DELETE_REMOVE_CAR: '/backend/cars',
  API_PUT_RESTORE_CAR: '/restore',

  API_GET_RESERVATION_BY_ID: '/backend/reservations',
  API_GET_ALL_RESERVATIONS: '/backend/reservations',
  API_POST_CREATE_RESERVATION: '/backend/reservations',
  API_PATCH_UPDATE_RESERVATION: '/backend/reservations',
  API_PATCH_SET_STATUS: '/backend/reservations',
  API_PATCH_SET_STATUS_STATUS: '/set-status',

  API_GET_ALL_PROMOTIONS: '/backend/promotions',
  API_GET_PROMOTION_BY_ID: '/backend/promotions',
  API_POST_CREATE_PROMOTION: '/backend/promotions',
  API_PATCH_UPDATE_PROMOTION: '/backend/promotions',
  API_DELETE_REMOVE_PROMOTION: '/backend/promotions',

  API_GET_ALL_ROLES: '/backend/roles',
  API_GET_ALL_LANGUAGES: '/backend/languages',

  API_GET_ALL_NOTIFICATIONS: '/profile/notifications',
  API_PATCH_NOTIFICAION_READ: '/profile/notifications/read',

  APP_LOCAL_STORAGE_TOKEN: 'access_token',
  APP_LOCAL_STORAGE_ROLE: 'active_role',

  APP_DEFAULT_PAGE_ROLE_SUPER_ADMIN: '/admin/reservation-list',
  APP_DEFAULT_PAGE_ROLE_OFFICE_ADMIN: '/admin/reservation-list',
  APP_DEFAULT_PAGE_ROLE_OPERATIVE_ADMIN: '/admin/cars-list',
  APP_DEFAULT_PAGE_ROLE_ASSOCIATE_ADMIN: '/admin/cars-list',
  APP_NOTIFICATION_LIFE: 7000,
};
