import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Observable, map, take } from 'rxjs';
import { User } from 'src/app/interfaces/user/user.model';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { SuperAdmin } from 'src/app/interfaces/user/roles/super-admin.model';
import { Router, RouterModule } from '@angular/router';
import { NotificationList } from 'src/app/interfaces/notification/notification-list.model';
import { NotificationsService } from 'src/app/services/notifications.service';
import { HighlightService } from 'src/app/services/highlight.service';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    OverlayPanelModule,
    ButtonModule,
    BadgeModule,
    RouterModule,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() user: User | null = null;
  notificationsOverlay: string = 'notifications-overlay';
  profileOverlay: string = 'profile-overlay';
  // activeUser$: Observable<User | null> = this.authService.activeUser$;
  allNotifications$: Observable<NotificationList> =
    this.notificationsService.headerNotifications$;
  constructor(
    public title: Title,
    private authService: AuthorizationService,
    private router: Router,
    private notificationsService: NotificationsService,
    private highlightService: HighlightService
  ) {}
  ngOnInit(): void {
    this.notificationsService.getHeaderNotifications();
  }

  onViewNotification(id: string) {
    this.highlightService.setElement(id);
  }

  onLogut() {
    this.authService.onLogOut();
  }
}
