import { Component, OnInit } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { User } from './interfaces/user/user.model';
import { AuthorizationService } from './services/authorization.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  menuClosed = true;
  activerUser = false;

  activeUser$: Observable<User | null> = this.authService.activeUser$;

  constructor(
    private authService: AuthorizationService,
    private router: Router
  ) {}
  ngOnInit(): void {
    if (localStorage.getItem(environment.APP_LOCAL_STORAGE_TOKEN)) {
      this.authService.getActiveUser();
      this.authService.getRoles();
    }
  }
  toggleMenu() {
    this.menuClosed = !this.menuClosed;
  }
  closeMenu() {
    this.menuClosed = true;
  }
}
